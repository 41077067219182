import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import moment from "moment";
import { Button } from "../../common/Button";
import { Close, Done, VideocamOutlined, FilePresent } from "@mui/icons-material";
import { eventType } from "./Constants";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getSingleUser } from "../../api/Individual";
import { failed, success } from "../../common/Toastify";
import { AttachementModal } from "./AttachementModal";
import { Tooltip } from "@mui/material";
import Video from "../../../assets/images/Video.svg";
import Audio from "../../../assets/images/Audio.svg";
import { axios } from "../../../lib/axios";
import { FullPageSpinner } from "../../common/Spinner/FullPageSpinner";
import { ViewDate, safeAgeString } from "../../../utils/DateSupport";
import { useSelector } from "react-redux";
import Fade from 'react-bootstrap/Fade';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

export function ViewAppointmentModal({ modalShow, handleShow, handleConnect, handleCall, handleUpdateRequest, appointmentsList, isProviderAppointment=false, setScheduleModal=false, url }) {
    const [data, setData] = useState("");
    const [patient, setPatient] = useState("");
    const [attachmentUrl, setAttachmentUrl] = useState('');
    const [showAttachmentModal, setShowAttachmentModal] = useState(false);
    const [loading, setLoading] = useState(false)
    const [documentData, setDocumentData] = useState({});
    const adminOrgs = useSelector((state) => state?.auth?.user?.organizations)
    const patientHCN = patient?.patient_identifier?.find((ele) => ele.type === "HCN")?.value;
    const [openStates, setOpenStates] = useState({});

    const handleToggle = (questionKey) => {
    setOpenStates((prevOpenStates) => ({
        ...prevOpenStates,
        [questionKey]: !prevOpenStates[questionKey],
    }));
    };

    useEffect(() => {
        setData(appointmentsList?.filter(data => data.id === modalShow)[0])
        if(data){
            getSingleUser({id : data.patientId, type: 'Patient', userOrgId: adminOrgs?.[0]?.id})
            .then((res) => setPatient(res.data))
            .catch((res) => {
                failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
            });
        }
    }, [modalShow, data])

    const handleScheduleModal = (appointmentData) => {
        setScheduleModal(appointmentData)
        handleShow()
    }

    var a = moment().format("X");
    var b = moment(data?.requestedPeriod?.[0]?.start?.replace("Z", "")).format("X");
    //const isTimeAvailable = parseInt(a) > parseInt(b);

    const handleShowFile = async(data) => {
        setLoading(true)
        let response
        try {
            response = await axios.get(`/appointment/${modalShow}`);
            if(response?.status){
                setLoading(false)
                const document = response?.data?.document?.find((obj)=> obj?.key == data?.key)
                setAttachmentUrl(data?.url)
                setDocumentData(document)
                setShowAttachmentModal(true)
            }
        } catch (error) {
           setLoading(false)
           failed(response?.message)
        }
    }

    return (
        <>
            {loading ? <FullPageSpinner /> : null}
            {showAttachmentModal ? <AttachementModal documentData={documentData} modalShow={showAttachmentModal} url={attachmentUrl} handleShow={() => setShowAttachmentModal(false)} /> : null}
            <Modal
                backdropClassName
                backdrop={'static'}
                show={modalShow} onHide={handleShow}
                aria-labelledby="contained-modal-title-vcenter"
                centered className="custom-dialog appointment-custom-modal">
                <Modal.Header closeButton className="border-0">
                    <span className="header-text">Patient:</span>&nbsp;&nbsp;
                    <Modal.Title id="contained-modal-title-vcenter" style={{ textTransform: "capitalize" }}>
                        {data?.participant?.filter((data) => data?.actor?.type === "Patient")?.[0]?.actor?.display}
                    </Modal.Title>&nbsp;&nbsp;&nbsp;&nbsp;
                    {/* Appointment type- Accepted */}
                    {(data?.status === "booked" && !isProviderAppointment) && (
                       <div className="action-wrap">
                            {/* <Tooltip title={data?.questionnaireResponse?.preferredChannel}>
                                {data?.questionnaireResponse?.preferredChannel === 'phone' || data?.questionnaireResponse?.preferredChannel === 'Phone' ? ( 
                                    <img src={Audio} alt="Audio Channel" /> 
                                  ) : (
                                    <img src={Video} alt="Video Channel" /> 
                                )}
                            </Tooltip> */}
                            <Tooltip title={data?.intakeQuestions?.preferredChannel}>
                                {data?.intakeQuestions?.preferredChannel === 'phone' || data?.intakeQuestions?.preferredChannel === 'Phone' ? ( 
                                    <img src={Audio} alt="Audio Channel" /> 
                                  ) : (
                                    <img src={Video} alt="Video Channel" /> 
                                )}
                            </Tooltip>
                       </div>
                    )}
                    {/* Appointment type- Pending */}
                    {data?.status === "proposed"  && (
                    //    <div className="action-wrap">
                    //         <Tooltip title={data?.questionnaireResponse?.preferredChannel}>
                    //             {data?.questionnaireResponse?.preferredChannel === 'phone' || data?.questionnaireResponse?.preferredChannel === 'Phone' ? ( 
                    //                 <img src={Audio} alt="Audio Channel" /> 
                    //               ) : (
                    //                 <img src={Video} alt="Video Channel" /> 
                    //             )}
                    //         </Tooltip>
                    //    </div>
                    <div className="action-wrap">
                            <Tooltip title={data?.intakeQuestions?.preferredChannel}>
                                {data?.intakeQuestions?.preferredChannel === 'phone' || data?.intakeQuestions?.preferredChannel === 'Phone' ? ( 
                                    <img src={Audio} alt="Audio Channel" /> 
                                  ) : (
                                    <img src={Video} alt="Video Channel" /> 
                                )}
                            </Tooltip>
                       </div>
                    )}
                    {/* Appointment type- Rejected */}
                    {data?.status === "cancelled"  && (
                       <div className="action-wrap">
                            {/* <Tooltip title={data?.questionnaireResponse?.preferredChannel}>
                                {data?.questionnaireResponse?.preferredChannel === 'phone' || data?.questionnaireResponse?.preferredChannel === 'Phone' ? ( 
                                    <img src={Audio} alt="Audio Channel" /> 
                                  ) : (
                                    <img src={Video} alt="Video Channel" /> 
                                )}
                            </Tooltip> */}
                            <Tooltip title={data?.intakeQuestions?.preferredChannel}>
                                {data?.intakeQuestions?.preferredChannel === 'phone' || data?.intakeQuestions?.preferredChannel === 'Phone' ? ( 
                                    <img src={Audio} alt="Audio Channel" /> 
                                  ) : (
                                    <img src={Video} alt="Video Channel" /> 
                                )}
                            </Tooltip>
                       </div>
                    )}
                    {/* Appointment type- Completed */}
                    {data?.status === "completed"  && (
                    //    <div className="action-wrap">
                    //         <Tooltip title={data?.questionnaireResponse?.preferredChannel}>
                    //             {data?.questionnaireResponse?.preferredChannel === 'phone' || data?.questionnaireResponse?.preferredChannel === 'Phone' ? ( 
                    //                 <img src={Audio} alt="Audio Channel" /> 
                    //               ) : (
                    //                 <img src={Video} alt="Video Channel" /> 
                    //             )}
                    //         </Tooltip>
                    //    </div>
                    <div className="action-wrap">
                            <Tooltip title={data?.intakeQuestions?.preferredChannel}>
                                {data?.intakeQuestions?.preferredChannel === 'phone' || data?.intakeQuestions?.preferredChannel === 'Phone' ? ( 
                                    <img src={Audio} alt="Audio Channel" /> 
                                  ) : (
                                    <img src={Video} alt="Video Channel" /> 
                                )}
                            </Tooltip>
                       </div>
                    )}
                    <div style={{ background: eventType?.[data?.status]?.backgroundColor, color: eventType?.[data?.status]?.textColor, borderRadius: "2rem", padding: "0 0.7rem", marginLeft: "clamp(0rem, 3.5vw, 3rem)" }}>{eventType?.[data?.status]?.name}</div>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col> 
                        <b>Appointment Details</b>
                            <div className="col-section">
                                <span className="label-grey">Date:</span>  {`${ViewDate(data?.requestedPeriod?.[0]?.start)}`} | <span className="label-grey">Time:</span>  {`${moment(data?.requestedPeriod?.[0]?.start).format("HH:mm")} - ${moment(data?.requestedPeriod?.[0]?.end).format("HH:mm")}`} <br/>
                                <span className="label-grey">Reason for visit:</span>  {data?.description}<br />
                                <span className="label-grey">Appointment Type:</span> {data?.serviceCategory?.[0]?.coding?.[0]?.display || "-"}<br />
                                <span className="label-grey">Return Telephone Number:</span> {data?.intakeQuestions?.callerNumber} { data?.intakeQuestions?.callerNumberExt && data?.intakeQuestions?.callerNumberExt != "" ? 'ext. ' + data?.intakeQuestions?.callerNumberExt : '' }<br/>
                                <span className="label-grey">Preferred Channel: </span>  <span style={{textTransform: "capitalize"}}>{data?.intakeQuestions?.preferredChannel}</span><br />
                                <span className="label-grey">Attachments:</span>
                                    {data?.document?.length > 0 && data?.document.map((pdfUrl, index) => (<Link onClick={()=> handleShowFile(pdfUrl)}> <FilePresent /> </Link>))}
                                    {data?.document?.url && ( <Link onClick={()=> handleShowFile(data?.document)}><FilePresent/></Link>)} 
                                    {!data?.document?.length > 0 && !data?.document?.url && (<span>-</span>)} <br />
                                
                                    
                            </div> 
                        </Col>
                        <Col>
                        <b>Patient Details</b>
                            <div className="col-section"> 
                                <span className="label-grey">Name: </span> <a href={`/app/patients-details?patientId=${data?.participant?.find(p => p?.actor?.type === "Patient")?.actor?.reference.split('/')[1]}`} style={{color: "#0064a6", textTransform: "capitalize"}} target="_blank" rel="noopener noreferrer"> {data?.participant?.filter((data) => data?.actor?.type === "Patient")?.[0]?.actor?.display}</a> | <span className="label-grey">Gender: </span>  <span style={{textTransform: "capitalize"}}>{patient?.gender}</span><br />
                                <span className="label-grey">DOB: </span>{ViewDate(patient?.birthDate)} | <span className="label-grey">Age: </span>{safeAgeString(patient?.birthDate)}<br />
                                <span className="label-grey">Phone: </span>  {patient?.telecom?.find((ele) => ele?.system === "phone")?.value || ""}<br />
                                <span className="label-grey">Email: </span>  {patient?.telecom?.find((ele) => ele?.system === "email")?.value || ""}<br />
                                <span className="label-grey">HCN: </span> {patientHCN || ""}  
                            </div>
                        </Col> 
                    </Row>  
                    <hr />
                    <Row className="my-3">
                        <Col>
                            <b>Intake Questions</b>
                            <div className="col-section"> 
                                {Object.entries(data?.intakeQuestions || {}).map(([questionKey, questionValue]) => {
                                    // Skip the questionnaireResponseId if it exists and remove duplicacy
                                    const excludedKeys = [
                                        'questionnaireResponseId',
                                        'callerNumber',
                                        'callerRelationship',
                                        'callerName',
                                        'callerNumberExt',
                                        'reason',
                                        'location'
                                      ]; 
                                      if (excludedKeys.includes(questionKey)) return null;
                                    return (
                                        <div key={questionKey}>
                                        <span className="label-grey">
                                          {questionKey.length > 100 ? (
                                            <>
                                              {questionKey.substring(0, 100) + ` `} 
                                              <span
                                                onClick={() => handleToggle(questionKey)}
                                                aria-controls="example-fade-text"
                                                aria-expanded={openStates[questionKey]}
                                                style={{ cursor: 'pointer', marginLeft: '5px' }}
                                              >
                                                <ArrowDownwardIcon style={{ fontSize: 'medium', marginRight: '5px' }} />
                                                <Fade in={openStates[questionKey]}>
                                                  <div id="example-fade-text" style={{ display: openStates[questionKey] ? 'block' : 'none' }}>
                                                    {questionKey.substring(100)}
                                                  </div>
                                                </Fade>
                                              </span>
                                            </>
                                          ) : (
                                            <>
                                              {questionKey.charAt(0).toUpperCase() + questionKey.slice(1) + ` `} 
                                            </>
                                          )}  
                                        </span>
                                        <span>{`: `+questionValue}</span><br />
                                      </div>
                                    );
                                })}
                            </div>
                        </Col>
                    </Row>

                    <br/>
                    <div className="btn-wrap-block">
                    {(data?.status === "booked" && !isProviderAppointment) 
                    ? <div className="action-wrap" style={{ display: "flex", justifyContent: "space-around"}}>
                            {/* <Button variant="primary" onClick={() => (data?.questionnaireResponse?.preferredChannel === 'phone' || data?.questionnaireResponse?.preferredChannel === 'Phone') ? handleCall(data) : handleConnect(data)} className="success-btn" style={{margin: "0 7px 0 0"}}>Connect</Button> */}
                            <Button variant="primary" onClick={() => (data?.intakeQuestions?.preferredChannel === 'phone' || data?.intakeQuestions?.preferredChannel === 'Phone') ? handleCall(data) : handleConnect(data)} className="success-btn" style={{margin: "0 7px 0 0"}}>Connect</Button>
                      </div>
                    : null }
                            {data?.status !== "cancelled" 
                                ?
                                    <>
                                    <div className="action-wrap" style={{ display: "flex", justifyContent: "space-around"}}>
                                        
                                        {data?.status === "proposed" ?
                                            data?.isFromWeb ?
                                                <span className="action-wrap" style={{ display: "flex", justifyContent: "space-around", margin: "0 15px 0 0" }}>Patient haven't accepted</span>
                                                : <>
                                                <span className="action-wrap" style={{ display: "flex", justifyContent: "space-around", marginTop: "0.15rem" }}>
                                                    <Button variant="primary" onClick={() => handleUpdateRequest(data, "booked")}
                                                        className="success-btn" startIcon={<Done style={{ color: "white" }} />} style={{ marginRight: "20px" }}>Accept</Button>
                                                    <Button variant="primary" onClick={() => handleUpdateRequest(data, "cancelled")}
                                                        className="delete-btn" startIcon={<Close style={{ color: "white" }} />}>Reject</Button>
                                                </span>
                                                </>
                                            : null}
                                        {(data?.status !== "completed" && data?.status !== "proposed") ?
                                            <Button  variant="primary" onClick={() => handleScheduleModal(data)} className="view-btn" style={{margin: "0 7px 0 0"}}>Reschedule</Button>
                                            : null}
                                        
                                    </div>
                                    {(data?.status === "booked" || (data?.status === "proposed" && data?.isFromWeb)) ?
                                    <div className="action-wrap" style={{ display: "flex", justifyContent: "space-around" }}>
                                    <Button variant="primary" onClick={() => handleUpdateRequest(data, "cancelled")}
                                        className="delete-btn" style={{margin: "0"}}>Cancel Appointment</Button>
                                         </div>
                                    : null}
                                    </>
                                : null}
                       {/* </div> */} 
                   {/* : null }  */}
                    </div>

                </Modal.Body>
            </Modal>
        </>
    );
}
