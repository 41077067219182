import { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import {
  bookAppointment,
  checkSchedule,
  getEncounterRecord,
  getSlots,
  updateAppointmentRequest,
} from "../api/AppointmentRequests";
import { failed, success } from "../../common/Toastify";
import { useFormik } from "formik";
import moment from "moment";
import { useSelector } from "react-redux";
import { generatePass } from "../AppointmentRequests/Constants";
import { getSignature } from "../../Zoom/functions";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { LOCATIONS } from "../../common/location";
import { editQuestionnaireResponse } from "../../api/Questionnaire";
import { getSingleUser } from "../../api/Individual";
import { decryptData } from "../../EncryptDecrypt";
import { CircularProgress, Skeleton } from "@mui/material";
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import TextInput from "../../common/textfield/TextInput";
import SelectFiled from "../../common/textfield/SelectFiled";
import { Button } from "../../common/Button";
import { DateSelectorWithoutFormik } from "../../common/textfield/DateSelector";
import { Form } from "react-bootstrap";
import { getScheduleSlots } from "../../api/Schedule";
import { on } from "ws";
import { getOrganization } from "../../api/Organization";
import { axios } from "../../../lib/axios";
import { createQuestionnaireResponse } from "../../api/Questionnaire";
import { buildSessionTopic } from "../../../utils/ZoomSessionSupport";

const ScheduleAppointmentWR = ({
  onShow,
  onHide,
  encounterId,
  questionnaireData,
  patientName,
  patientId,
  type = "patient",
  questionnaireResponseId,
}) => {
  const [encounterData, setEncounterData] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const [practitionerList, setPractitionerList] = useState([]);
  const user = useSelector((state) => state?.auth?.user);
  const [slotsLoading, setSlotsLoading] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState("");
  const [availableSlots, setAvailableSlots] = useState([]);
  const [availableDays, setAvailableDays] = useState([]);
  const [slotUnavailable, setSlotUnavailable] = useState(false);
  const [practitionerId, setPractitionerId] = useState(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [serviceCategory, setServiceCategory] = useState([]);
  const [categoriesByDay, setCategoriesByDay] = useState({});
  const [reservedCategories, setReservedCategories] = useState({});
  const practitionerUniqueId = useSelector( (state) => state?.auth?.user?.["custom:unique_id"]);
  const userOrg = useSelector((state) => state?.auth?.user?.organizations)
  const userOrgId = userOrg?.[0]?.id;
  const adminOrgs = useSelector((state) => state?.auth?.user?.organizations);
  const [Questions, setQuestions] = useState([]);

  useEffect(() => {
    getEncounterRecord({ encounterId: encounterId })
      .then((res) => {
        if (res?.status === true) {
          setEncounterData(res?.data);
        }
      })
      .catch((res) =>
        failed(
          res?.response?.data?.message ||
            res?.response?.data?.error ||
            res.message
        )
      );
      getOrganization(adminOrgs?.[0]?.id)
      .then(async (res) => { 
        if (res.data?.assignedQuestionnaire) {
          const response = await axios.get(
            `questionnaire/${res.data?.assignedQuestionnaire?.["telephone-intake"]}`
          );
          formik.setFieldValue("questionnaireId", response?.data?.id);
          setQuestions(
            response?.data?.item?.map((item) => ({
              ...item,
              name: item?.text,
              value: item?.id,
            }))
          ); 
        }
      })
      .catch((res) => {
        failed(
          res?.response?.data?.message ||
            res?.response?.data?.error ||
            res.message
        );
      });
  }, []);

  useEffect(() => {
    getSingleUser({ orgId: user?.organizations[0]?.id, type: "Practitioner" })
      .then((res) => {
        setPractitionerList(
          res?.data?.map(
            (ele) =>
              (ele = {
                value: ele?.id,
                name: decryptData(
                  ele?.name?.[0]?.text
                    ?.split(" ")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")
                ),
                id: ele?.id,
              })
          )
        );
      })
      .catch((res) => {
        console.log("error fetching practitioner list", res);
      });
  }, []);

  const formik = useFormik({
    initialValues: {
      title: "",
      patient: "",
      date: "",
      serviceCategory: "",
      availableSlot: "",
      preferredChannel: "",
      returnTelephoneNumber: "",
      returnTelephoneNumberExt: "",
      practitionerPreference: "",
      practitioner: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Required Reason for visit field."),
      patient: Yup.string().required("Required Patient field."),
      date: Yup.string().required("Required Date field."),
      serviceCategory: Yup.string().required(
        "Required Appointment type field."
      ),
      availableSlot: Yup.object().required("Required slots field."),
      preferredChannel: Yup.string().required(
        "Required Preferred channel field."
      ),
      returnTelephoneNumber: Yup.string()
        .matches(
          /^(?:\+?1[-. ]?)?\(?[0-9]\d{2}\)?[-. ]?\d{3}[-. ]?\d{4}$/,
          "Enter Valid Return Telephone Number."
        )
        .required("Required Return Telephone Number field."),
      returnTelephoneNumberExt: Yup.string().matches(
        /^\d{0,4}$/,
        "Must be 4 digit or less."
      ),
      practitionerPreference:
        type === "patient"
          ? Yup.string().required("Required Practitioner Preference field.")
          : null,
      practitioner:
        type === "patient"
          ? Yup.mixed().when(["practitionerPreference"], {
              is: (practitionerPreference) =>
                practitionerPreference !== "no preference",
              then: Yup.string().required("Required Practitioner field."),
              otherwise: Yup.mixed().notRequired(),
            })
          : null,
    }),
    onSubmit: (values) => {
      setBtnLoading(true);
      let slot = null;
      let utcStart = values?.availableSlot?.start;
      let utcEnd = values?.availableSlot?.end;
      // let start = `${moment(values?.date)?.format("YYYY-MM-DD")}T${
      //   values?.availableSlot?.start
      // }:00Z`;
      // let localStartTime = moment(start).format("HH:mm");
      // let localStart = `${moment(values?.date)?.format(
      //   "YYYY-MM-DD"
      // )} ${localStartTime}`;
      // let utcStart = moment(localStart).utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
      // let end = `${moment(values?.date)?.format("YYYY-MM-DD")}T${
      //   values?.availableSlot?.end
      // }:00Z`;
      // let localEndTime = moment(end).format("HH:mm");
      // let localEnd = `${moment(values?.date)?.format(
      //   "YYYY-MM-DD"
      // )} ${localEndTime}`;
      // let utcEnd = moment(localEnd).utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
      let selectedServiceCategory = values?.serviceCategory;
      if (selectedServiceCategory.endsWith("busyReserved")) {
        selectedServiceCategory = selectedServiceCategory.split("-")?.[0];
      }

      selectedServiceCategory = serviceCategory.filter(
        (categoryData) => categoryData.id === selectedServiceCategory
      )?.[0];

      let serviceCategoryPayload = [
        {
          coding: [
            {
              system: "http://florizel.com/AppointmentServiceCategory",
              code: selectedServiceCategory?.id,
              display: selectedServiceCategory?.display
                ? selectedServiceCategory?.display
                : selectedServiceCategory?.name,
            },
          ],
          text: `Appointment for ${
            selectedServiceCategory?.display
              ? selectedServiceCategory?.display
              : selectedServiceCategory?.name
          }`,
        },
      ];
      if (values?.availableSlot?.status === "busy-reserved") {
        utcStart = values?.availableSlot?.start;
        utcEnd = values?.availableSlot?.end;
        slot = values?.availableSlot;
      } else if (formik?.values?.practitionerPreference === "no preference") {
        values.practitioner = values?.availableSlot?.actorId;
        values.scheduleId = values?.availableSlot?.scheduleId;
      } else values.scheduleId = values?.availableSlot?.scheduleId;
      const requestedPeriod = [{ start: utcStart, end: utcEnd }];
      
      let topic = (user?.name[0]?.text); 
      let password = generatePass();
      getSignature({ topic: topic.trim(), role: 0, password: password })
        .then(async (res) => { 
          const sessionToken = res?.data?.sessionToken;
          const questionResponse = Questions.map(item => {
            return {
              id: item.linkId,
              question: item.text,
              answer: item.answerOption.map(option => ({
                id: option.id,
                required: item.required,
                answer: "",  
                option: item.answerType === "radio" ? option.text : null,
                value: item.answerType === "dropDown" ? option.text : null,
                name: item.answerType === "dropDown" ? option.text : null
              })),
              questionType: item.answerType,
              required: item.required
            };
          });
          const locationKey = Object.keys(LOCATIONS).find(key => LOCATIONS[key] === encounterData?.patientLocation);  
          questionResponse[0].answer[0].answer = patientName.replace(/\b\w/g, c => c.toUpperCase());
          questionResponse[1].answer[0].answer = values?.returnTelephoneNumber;
          questionResponse[2].answer[0].answer = questionnaireData?.callerRelationship == "Self" ? true : false;
          questionResponse[4].answer[0].answer = questionnaireData?.consent == "Yes" ? true : false;
          questionResponse[6].answer[0].answer = values?.title;
          questionResponse[5].answer[0].answer = locationKey || "";  
          createQuestionnaireResponse({
            patientID: patientId,
            encounterId: "",
            questionResponse: questionResponse,
            appointmentId: "",
            questionnaire: values.questionnaireId,
          })
            .then((res) => { 
              let questionnaireResponseId =res.data.id;
              let intakeQuestions = {};
              if (type === "patient") { 
                intakeQuestions["questionnaireResponseId"] = {
                  reference: `QuestionnaireResponse/${questionnaireResponseId}`,
                };
                intakeQuestions["preferredChannel"] = values?.preferredChannel;
                intakeQuestions["callerName"] = patientName.replace(/\b\w/g, c => c.toUpperCase());
                intakeQuestions["callerNumber"] = values?.returnTelephoneNumber;
                intakeQuestions["callerRelationship"] =
                  questionnaireData?.callerRelationship;
                intakeQuestions["reason"] = values?.title;
                intakeQuestions["location"] = encounterData?.patientLocation || "";
              } 
              bookAppointment({
                serviceCategory: serviceCategoryPayload,
                slot,
                sessionToken,
                practitionerId: practitionerUniqueId,
                patientId: patientId,
                requestedPeriod,
                description: values?.title,
                intakeQuestions,
                patientLocation: intakeQuestions.location,
                scheduleId: values?.scheduleId,
              })
                .then((res) => {
                  if (type === "patient") {
                    localStorage.removeItem("firstName");
                    localStorage.removeItem("lastName");
                    let updatePayload = {
                      isHostJoined: false,
                      sessionToken: sessionToken,
                      id: res?.result?.id,
                      status: "booked",
                      patientId: res?.result?.patientId,
                      practitionerId: res?.result?.practitionerId,
                      proceedWith: questionnaireData?.howToProcced || "SCHEDULE",
                      intakeQuestions,
                      patientLocation: intakeQuestions.location,
                    };
                    updateAppointmentRequest(updatePayload)
                      .then((res) => {
                          if(res.status === true){
                            editQuestionnaireResponse(questionnaireResponseId, {encounterId: res?.result?.encounterId}).then((res)=>{
                                onHide();
                                success("Appointment Scheduled");
                              }).catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
                              .finally(() => setBtnLoading(false));
                        }  
                      })
                      .catch((err) =>
                        failed(
                          err?.response?.data?.message ||
                            err?.response?.data?.error ||
                            err?.message
                        )
                      )
                      .finally(() => setBtnLoading(true));
                  }
                })
                .catch((res) =>
                  failed(
                    res?.response?.data?.message ||
                      res?.response?.data?.error ||
                      res.message
                  )
                )
                .finally(() => setBtnLoading(true));
            })
            .catch((res) => {
              failed(
                res?.response?.data?.message ||
                  res?.response?.data?.error ||
                  res.message
              );
            })
            .finally(() => {
              setBtnLoading(true);
            }); 
        })
        .catch((error) => console.log("getsignature error", { error }));
    },
  });
  useEffect(() => {
    if (questionnaireData) {
      formik.setFieldValue("title", questionnaireData?.intakeReason || "");
      formik.setFieldValue(
        "returnTelephoneNumber",
        questionnaireData?.callerNumber || ""
      );
      formik.setFieldValue(
        "returnTelephoneNumberExt",
        questionnaireData?.callerNumberExt || ""
      );
      formik.setFieldValue("patient", patientName || "");
    }
  }, []);

  // const handleServiceCategory = (event) => {
  //   setSlotUnavailable(false);
  //   let targetVal = event?.target?.value;
  //   formik.setFieldValue("serviceCategory", targetVal);
  //   setSlotsLoading(true);
  //   if (targetVal?.endsWith("busyReserved")) {
  //     let currentTime = moment();
  //     let slots = reservedCategories[targetVal.split("-")[0]];
  //     slots = slots.filter((slot) => moment(slot.start).isAfter(currentTime));
  //     slots = sortedSlots(slots);
  //     if (slots.length < 1) {
  //       setSlotUnavailable(true);
  //     }
  //     setAvailableSlots(slots);
  //     setSlotsLoading(false);
  //   } else if (targetVal) {
  //     /** retrieve all slots here */
  //     let params = {
  //       actorId: practitionerUniqueId,
  //       // date: moment(formik?.values?.date).format("YYYY-MM-DD"),
  //       date: moment(formik?.values?.date).utc().format("YYYY-MM-DDTHH:mm:ss") + "Z",
  //       serviceCategoryId: targetVal,
  //       orgId:
  //         formik?.values?.practitionerPreference === "no preference"
  //           ? user?.organizations[0]?.id
  //           : userOrgId
  //     }

  //     if(formik?.values?.practitionerPreference === "no preference") params.scopeOfPractice = encounterData?.patientLocation
  //     getSlots(params)
  //       .then((res) => {
  //         setAvailableSlots(sortedSlots(res?.data?.availableSlots));
  //         if (res?.data?.availableSlots < 1) {
  //           setSlotUnavailable(true);
  //         }
  //       })
  //       .catch((res) => {
  //         failed(
  //           res?.response?.data?.message ||
  //             res?.response?.data?.error ||
  //             res.message
  //         );
  //         setAvailableSlots([]);
  //       })
  //       .finally(() => {
  //         setSlotsLoading(false);
  //       });
  //   } else setSlotsLoading(false);
  // };

  const loadSlotData = (selectedDate, selectedType) => {
    setSlotsLoading(true);
    let date = (selectedDate) ? selectedDate : formik?.values?.date;
    let type = (selectedType) ? selectedType : formik?.values?.serviceCategory;
    date = moment(date).utc().format("YYYY-MM-DDTHH:mm:ss") + "Z"
    if(type?.endsWith("busyReserved")){
        let currentTime = moment(date);
        let slots = reservedCategories[type.split("-")[0]];
        slots = slots.filter(slot => moment(slot.start).isAfter(currentTime) && moment(slot.start).isSame(currentTime, "day"))
        slots = sortedSlots(slots);
        if (slots.length < 1) {
            setSlotUnavailable(true)
        }
        setAvailableSlots(slots)
        setSlotsLoading(false)
    } else if(type) {
        /** retrieve all slots here */
        let params = { actorId: practitionerId || user["custom:unique_id"], date, serviceCategoryId: type, orgId: formik?.values?.practitionerPreference === "no preference" ? user?.organizations[0]?.id : userOrgId }
        if(formik?.values?.practitionerPreference === "no preference") {
          params.scopeOfPractice = encounterData?.patientLocation
        }
        getSlots(params)
            .then((res) => {
                setAvailableSlots(sortedSlots(res?.data?.availableSlots))
                if (res?.data?.availableSlots < 1) {
                setSlotUnavailable(true)
            }
            }).catch((res) => {
                failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                setAvailableSlots([])
            }).finally(() => { setSlotsLoading(false) })
    }
    else setSlotsLoading(false);  
  } 

  const handleDateSelection = (val) => {
    const isValidDate = moment(val, 'MMM-DD-YYYY').isValid()
    setAvailableSlots([])
    if(type === "patient"){
        if(formik.values.practitionerPreference == ''){
            failed('Please select practitioner preference')
        }else{
            if(isValidDate){
                formik.setFieldValue("date", val.format("YYYY-MM-DD"));
                // load slots here
                loadSlotData(val.format("YYYY-MM-DD"))
            }else{
                formik.setFieldError('date', 'Invalid date format')
            }
        }
    }else{
        if(isValidDate){
            formik.setFieldValue("date", val.format("YYYY-MM-DD"));
            loadSlotData(val.format("YYYY-MM-DD"))
        }else{
            formik.setFieldError('date', 'Invalid date format')
        }
    }       
  }

  // const handleDateChange = (val) => {
  //   const isValidDate = moment(val, "MMM-DD-YYYY").isValid();
  //   if (type === "patient") {
  //     if (formik.values.practitionerPreference == "") {
  //       failed("Please select practitioner preference");
  //     } else {
  //       if (isValidDate) {
  //         formik.setFieldValue("date", val.format("YYYY-MM-DD"));
  //         formik.setFieldValue("serviceCategory", "");
  //         setAvailableSlots([]);
  //       } else {
  //         formik.setFieldError("date", "Invalid date format");
  //       }
  //     } 
  //   } else {
  //     if (isValidDate) {
  //       formik.setFieldValue("date", val.format("YYYY-MM-DD"));
  //       formik.setFieldValue("serviceCategory", "");
  //       setAvailableSlots([]);
  //     } else {
  //       formik.setFieldError("date", "Invalid date format");
  //     }
  //   }
  // };

  const handlePractitionerChange = (event) => {
    setPractitionerId(event?.target?.value)
    formik.setFieldValue("practitioner", formik?.values?.practitionerPreference === "no preference" ? "" : event?.target?.value);
    setAvailableSlots([]);
  };


  const sortedSlots = (data) => {
    return data.sort((a, b) => {
      let atime = (moment(a.start).isValid()) ? `${a.start}`:`${moment(formik.values.date)?.format("YYYY-MM-DD")}T${a?.start}:00Z`;
      let btime = (moment(b.start).isValid()) ? `${b.start}`:`${moment(formik.values.date)?.format("YYYY-MM-DD")}T${b?.start}:00Z`;
      let aHr = moment(atime).hour();
      let bHr = moment(btime).hour();
      let aMin = moment(atime).minute();
      let bMin = moment(btime).minute();
      return aHr < bHr || (aHr == bHr && aMin < bMin) ? -1 : 1;
    });
  };

  const findUpcoming = (event) => {
    setSlotUnavailable(false);        
    let targetVal = event?.target?.value;
    formik.setFieldValue("serviceCategory", targetVal);
    setAvailableSlots([])
    formik.setFieldValue("date", "");
  }

  const highLightDates = () => {
    setAvailableDays([]);
    let today = moment();
    let toHighlight = [];
    const isBusyReserved = formik.values.serviceCategory.endsWith('busyReserved');
    if (!isBusyReserved) {
        for (const date in categoriesByDay) {
            if (moment(date).isSameOrAfter(today, 'day')) {
                if (categoriesByDay[date].some(item => item === formik.values.serviceCategory)) {
                    toHighlight.push(date);
                }
            }
        }
    } else {
        for (const reservedDate in reservedCategories) {
            const reservations = reservedCategories[reservedDate];
            reservations.forEach(reservation => {
                if (reservation.serviceCategory.some(category => formik.values.serviceCategory.includes(category.id))) {
                    const reservationStart = moment(reservation.start);
                    if (reservationStart.isSameOrAfter(today, 'day')) {
                        toHighlight.push(reservationStart.format('YYYY-MM-DD'));
                    }
                }
            });
        }
    }
    setAvailableDays(toHighlight);
  }

  const renderDay = (props) => {
    const { selectedDate, day, dayComponent, ...other} = props;
    const today = moment();
    const isAvailable = availableDays.includes(day.format("YYYY-MM-DD"));
    const isSelected = day.isSame(selectedDate, "day");
    const isToday = day.isSame(today, "day");
    const isPast = day.isBefore(today, "day")

    return (
        <PickersDay {...dayComponent} 
            disabled={isPast || !isAvailable}
            style={isAvailable ? {background: "#accfeb"}: {background: "white" }} />
    );
  }

  useEffect(() => {
    let startDate = (formik?.values?.date?.length > 0) ? moment(formik?.values?.date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")
    // if (formik?.values?.date) {
    if(formik?.values?.practitionerPreference &&
         ((formik?.values?.practitionerPreference === "select practitioner" && practitionerId)
            || formik?.values?.practitionerPreference === "no preference" )) {
        checkSchedule({actorId: (formik?.values?.practitionerPreference === "no preference" ? user["custom:unique_id"] :  practitionerId), date: startDate, orgId: (formik?.values?.practitionerPreference === "no preference" ? user?.organizations[0]?.id : null)}) 
        .then((res) => { 
                if(!res?.data) {
                    failed(res?.message);
                    setServiceCategory([]);
                    setCategoriesByDay({});
                    return;
                }
                
                let categories = [];
                if (!Array.isArray(res.data)) res.data = [res.data];
                
                res.data.forEach(schedule => {
                    let tempByDay = {};
                    schedule.serviceCategory.forEach(category => {
                        let key = category?.date?.split('T')?.[0];
                        if(moment(key).isSameOrAfter(moment(startDate)) && 
                            !categories.some(item => item.value === category.id)) {
                            categories.push({...category, value: category?.id});                                
                        }
                        if(moment(key).isSameOrAfter(moment(startDate))) {
                            if(tempByDay[key]) {
                                if(!tempByDay[key].some(id => id === category.id )) 
                                    tempByDay[key].push(category.id)
                            }
                            else tempByDay[key] = [category.id];   
                        }
                    })
                    setCategoriesByDay(Object.assign(categoriesByDay, tempByDay))                       
                })
                let reservedSlots ={}
                let newData = [];
                res.data.forEach(schedule => 
                    getScheduleSlots(schedule.id, "busy-reserved")
                    .then((resp) => {
                        resp.data.filter((slot)=> moment(slot?.start).isSameOrAfter(moment(startDate)))
                        ?.map((slot) => {
                            let serviceCategoryId = slot?.serviceCategory?.[0]?.id
                            
                            if (reservedSlots.hasOwnProperty(serviceCategoryId)) {
                                reservedSlots[serviceCategoryId].push({
                                    "start": slot?.start,
                                    "end": slot?.end,
                                    "id": slot?.id,
                                    "serviceCategory": slot.serviceCategory,
                                    "status": "busy-reserved"
                                });
                            }    
                            else {
                                reservedSlots[serviceCategoryId] = [{
                                    "start": slot?.start,
                                    "end": slot?.end,
                                    "id": slot?.id,
                                    "serviceCategory": slot.serviceCategory,
                                    "status": "busy-reserved"
                            }];
                            }
                        })

                        Object.keys(reservedSlots).forEach(key => {
                            if(!newData.some(item => item.value === `${reservedSlots[key][0]?.serviceCategory?.[0]?.id}-busyReserved`))
                            newData.push({
                                name: `Reserved for ${reservedSlots[key]?.[0]?.serviceCategory[0]?.name}`,
                                value: `${reservedSlots[key][0]?.serviceCategory?.[0]?.id}-busyReserved`,
                                status: "busy-reserved"
                            });
                            
                        });
                        setReservedCategories(reservedSlots);
                        setServiceCategory([...categories, ...newData])                            
                    })
                    .catch((res) => {
                        failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                        setServiceCategory([]);
                        setCategoriesByDay({});
                    })
                );
            })
            .catch((res) => {
                failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                setServiceCategory([]);
                setCategoriesByDay({});
            });
    }
  }, [practitionerId]);

  return (
    <>
      <Modal
        backdropClassName
        backdrop={"static"}
        show={onShow}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="custom-dialog appointment-custom-modal"
      >
        <Modal.Header closeButton className="border-0">
          <Modal.Title id="contained-modal-title-vcenter">
            Create Appointment for{" "}
            <span style={{ textTransform: "capitalize" }}>{patientName}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            className="common-form border-fields"
            onSubmit={formik.handleSubmit}
          >
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50vh",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                <Row>
                  <Col>
                    <TextInput
                      keyField={"title"}
                      label={"Reason for visit"}
                      formik={formik}
                      placeholder={"Reason for visit"}
                    />
                  </Col>
                  <Col>
                    {!type ? null : (
                      <TextInput
                        keyField={"patient"}
                        label={"Patient Name"}
                        formik={formik}
                        disabled={true}
                        placeholder={"Patient Name"}
                        style={{ textTransform: "capitalize" }}
                      />
                    )}
                  </Col>
                </Row>
                {type === "patient" ? (
                  <Row>
                    <Col>
                      <Form.Check.Label
                        style={{
                          textTransform: "capitalize",
                          fontSize: "14px",
                        }}
                      >
                        {"practitioner preference ?"}
                      </Form.Check.Label>{" "}
                      <span className="requiredField">&#42;</span>
                      <br />
                      {["select practitioner", "no preference"].map((val) => (
                        <Form.Check
                          style={{
                            textTransform: "capitalize",
                            fontSize: "14px",
                            marginRight: "45px",
                          }}
                          inline
                          checked={formik.values.practitionerPreference === val}
                          type={"radio"}
                          label={val}
                          id={val}
                          name="practitioner preference"
                          onChange={(e) => {
                            setPractitionerId(null);
                            formik.setFieldValue(
                              "practitionerPreference",
                              e.target.id
                            );
                          }}
                        />
                      ))}
                      {formik?.touched["practitionerPreference"] &&
                      formik?.errors["practitionerPreference"] ? (
                        <div className="error-text">
                          {formik?.errors["practitionerPreference"]}{" "}
                        </div>
                      ) : null}
                    </Col>
                    {formik?.values?.practitionerPreference ===
                    "select practitioner" ? (
                      <Col>
                        <SelectFiled
                          keyField={"practitioner"}
                          label={"Select Practitioner"}
                          formik={formik}
                          options={practitionerList}
                          isReadOnly={true}
                          onChange={handlePractitionerChange}
                        />
                      </Col>
                    ) : (
                      <Col></Col>
                    )}
                  </Row>
                ) : null}
                <Row className="mt-3">
                  <Col>
                    <SelectFiled keyField={"serviceCategory"} label={"Appointment Type"} formik={formik} options={serviceCategory} disabled={!formik.values.practitionerPreference} onChange={findUpcoming} />
                  </Col>
                  <Col>
                    <DateSelectorWithoutFormik disabled={!formik?.values?.serviceCategory} formik={formik} keyField={'date'} label="Date" value={moment(formik?.values?.date)} handleChange={(value) => { handleDateSelection(value)}} minDate={moment()} defaultValue={moment()} onMonthChange={highLightDates} onOpen={highLightDates} renderDay={(day, selectedDate, dayComponent) => renderDay({day, selectedDate, dayComponent})} />
                  </Col>
                </Row>
                {availableSlots?.length > 0 ? (
                  <>
                    <Row>
                      <Col>Select Slot</Col>
                    </Row>
                    <Row
                      style={{
                        rowGap: "0.5rem",
                        marginBottom: "1.5rem",
                        marginTop: "0.5rem",
                      }}
                    >
                      {availableSlots?.map((slot, index) => {
                        let time;
                        let localTime;
                        if (slot?.status === "busy-reserved") {
                          localTime = moment(slot?.start).format("HH:mm");
                        } else {
                          localTime = moment(slot?.start).format("HH:mm");
                        }

                        return (
                          <Col
                            style={{
                              background:
                                selectedSlot === slot?.start
                                  ? "#6c757d38"
                                  : "#ffff",
                              width: "14.666667%",
                            }}
                            sm={2}
                            className="slotStyle"
                            key={index}
                            onClick={() => {
                              formik?.setFieldValue("availableSlot", slot);
                              setSelectedSlot(slot?.start);
                            }}
                          >
                            {localTime}
                          </Col>
                        );
                      })}
                    </Row>
                  </>
                ) : (
                  <div
                    className="error-text"
                    style={{
                      marginBottom: "1.5rem",
                      display: slotUnavailable ? "block" : "none",
                    }}
                  >
                    No Available Slots
                  </div>
                )}

                {slotsLoading ? (
                  <>
                    <Row>
                      <Col>Select Slot</Col>
                    </Row>
                    <Skeleton height={50} />
                  </>
                ) : null}
                <Row>
                  <Col>
                    <Form.Check.Label
                      style={{ textTransform: "capitalize", fontSize: "14px" }}
                    >
                      {"preferred channel ?"}
                    </Form.Check.Label>{" "}
                    <span className="requiredField">&#42;</span>
                    <br />
                    {["phone", "video/chat"].map((val) => (
                      <Form.Check
                        style={{
                          textTransform: "capitalize",
                          fontSize: "14px",
                          marginRight: "65px",
                        }}
                        inline
                        checked={formik.values.preferredChannel === val}
                        type={"radio"}
                        label={val}
                        id={val}
                        name="preferred channel"
                        onChange={(e) =>
                          formik.setFieldValue("preferredChannel", e.target.id)
                        }
                      />
                    ))}
                    {formik?.touched["preferredChannel"] &&
                    formik?.errors["preferredChannel"] ? (
                      <div className="error-text">
                        {formik?.errors["preferredChannel"]}{" "}
                      </div>
                    ) : null}
                  </Col>
                  {type !== "patient" ? null : (
                    <>
                      <Col>
                        <Row>
                          <Col>
                            <TextInput
                              keyField={"returnTelephoneNumber"}
                              type="phone"
                              label={"Return Telephone Number"}
                              formik={formik}
                              disabled={false}
                              placeholder={"Return Telephone Number"}
                              required={true}
                            />
                          </Col>
                          <Col>
                            <TextInput
                              keyField={"returnTelephoneNumberExt"}
                              label={"Ext."}
                              formik={formik}
                              disabled={false}
                              placeholder={"Ext."}
                              required={false}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </>
                  )}
                </Row>

                <Row></Row>
                <Row></Row>
              </>
            )}
            <div className="btn-wrap">
              <Button
                onClick={() => {
                  onHide();
                }}
                variant="secondary"
                title="Cancel"
              >
                Cancel
              </Button>
              <Button type="submit" isLoading={btnLoading}>
                Create
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ScheduleAppointmentWR;
