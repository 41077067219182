import { useFormik } from "formik";
import React, { useEffect } from "react";
import * as Yup from "yup";
import { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import TextInput from "../../common/textfield/TextInput";
import TextArea from "../../common/textfield/TextArea";
import { usePatientsBySOPQuery } from "../../../hooks/ReactQueryHooks/usePatientsBySOPQuery";
import { useScheduleCategoryQuery } from "../../../hooks/ReactQueryHooks/useScheduleCategoryQuery";
import SelectFiled from "../../common/textfield/SelectFiled";
import { Button } from "../../common/Button";
import { CircularProgress, Skeleton } from "@mui/material";
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { DateSelectorWithoutFormik } from "../../common/textfield/DateSelector";
import moment from "moment";
import { bookAppointment, checkSchedule, getSlots, rescheduleAppointment, getlistPractitioners } from "../api/AppointmentRequests";
import { getScheduleSlots, getSlotById, updateScheduleSlot} from "../../api/Schedule";
import { failed, success } from "../../common/Toastify";
import "../AppointmentRequests/style.css"
import { generatePass } from "../AppointmentRequests/Constants";
import { useSelector } from "react-redux";
import { getSignature } from "../../Zoom/functions";
import { updateAppointmentRequest } from '../api/AppointmentRequests';
import { Form } from "react-bootstrap";

const ReschduleAppointment = ({ modalShow, handleShow }) => {
    const user = useSelector((state) => state?.auth?.user);
    const [btnLoading, setBtnLoading] = useState(false);
    const [slotsLoading, setSlotsLoading] = useState(false);
    const [slotUnavailable, setSlotUnavailable] = useState(false);
    const [selectedSlot, setSelectedSlot] = useState("");
    const [serviceCategory, setServiceCategory] = useState([]);
    const [categoriesByDay, setCategoriesByDay] = useState({});
    const [reservedCategories, setReservedCategories] = useState ({})
    const [practitionersList, setpractitionersList] = useState([]);
    const [availableSlots, setAvailableSlots] = useState([]);
    const [availableDays, setAvailableDays] = useState([]);
    const userOrg = useSelector((state) => state?.auth?.user?.organizations)
    const userOrgId = userOrg?.[0]?.id;
    const preferredChannelType = modalShow?.intakeQuestions?.preferredChannel === "phone" || modalShow?.intakeQuestions?.preferredChannel === "Phone";
    
    useScheduleCategoryQuery({
        onSuccess: (data) => setServiceCategory(data.map((item) => ({
            name: `${item?.name} (${item?.timeSlot?.value} min)`,
            value: item.id,
            id: item.id,
            timeSlot: item.timeSlot,
        })))
    })
    const formik = useFormik({
        initialValues: {
            title: "",
            patient: "",
            // description: "",
            date: "",
            serviceCategory: "",
            availableSlot: "",
            preferredChannel: modalShow?.intakeQuestions?.preferredChannel,
            returnTelephoneNumber: modalShow?.intakeQuestions?.callerNumber,
            returnTelephoneNumberExt: modalShow?.intakeQuestions?.callerNumberExt,
            practitioner: modalShow?.practitionerId,
        },
        validationSchema: Yup.object({
            title: Yup.string().required("Required Reason for visit field"),
            patient: Yup.string().required("Required Patient field"),
            // description: Yup.string().required("Required Description field"),
            date: Yup.string().required("Required Date field"),
            serviceCategory: Yup.string().required("Required Appointment type field"),
            practitioner: Yup.string().required("Required Practitioner  field"),
            availableSlot: Yup.object().required("Required slots field"),
        }),
        onSubmit: (values) => {
            setBtnLoading(true);
            let slot = null;

            // let start =`${moment(values?.date)?.format("YYYY-MM-DD")}T${(values?.availableSlot?.start)}:00Z`;
            // let localStartTime = moment(start).format("HH:mm")
            // let localStart = `${moment(values?.date)?.format("YYYY-MM-DD")} ${(localStartTime)}`;
            // let utcStart = moment(localStart).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
            // let end = `${moment(values?.date)?.format("YYYY-MM-DD")}T${(values?.availableSlot?.end)}:00Z`;
            // let localEndTime = moment(end).format("HH:mm")
            // let localEnd = `${moment(values?.date)?.format("YYYY-MM-DD")} ${(localEndTime)}`;
            // let utcEnd = moment(localEnd).utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
            let utcStart = values?.availableSlot?.start;
            let utcEnd = values?.availableSlot?.end;
            let selectedServiceCategory = values?.serviceCategory;
            let intakeQuestions =modalShow?.intakeQuestions;
            intakeQuestions["preferredChannel"] = values?.preferredChannel;


            // if(selectedServiceCategory.endsWith("busyReserved")){
            //     selectedServiceCategory = selectedServiceCategory.split("-")?.[0];
            // }
            selectedServiceCategory = serviceCategory.filter((categoryData) => categoryData.value === selectedServiceCategory)?.[0]
            if(selectedServiceCategory?.value?.endsWith("busyReserved")) {
                selectedServiceCategory.id = selectedServiceCategory?.value?.split("-")[0];
                selectedServiceCategory.name = selectedServiceCategory.name.substring(12).trim();
            }
            let serviceCategoryPayload = [
                {
                  coding: [
                    {
                      system: "http://florizel.com/AppointmentServiceCategory",
                      code: selectedServiceCategory?.id,
                      display: selectedServiceCategory?.display ? selectedServiceCategory?.display : selectedServiceCategory?.name
                    }
                  ],
                  text: `Appointment for ${selectedServiceCategory?.display ? selectedServiceCategory?.display : selectedServiceCategory?.name}`
                }
            ]
            if(values?.availableSlot.status === "busy-reserved"){
                utcStart= values?.availableSlot?.start
                utcEnd = values?.availableSlot?.end
                slot = values?.availableSlot;
            }
            const requestedPeriod = [{start: utcStart, end: utcEnd}];
            let keepOldSlot = false;
            getSlotById(modalShow?.slotId)
            .then((response) =>{
            let data = response?.data?.[0]
            if(data?.serviceCategory?.[0]?.id){
                keepOldSlot = true;
            }
            rescheduleAppointment({serviceCategory: serviceCategoryPayload, intakeQuestions, keepOldSlot, slot,  appointmentId: modalShow?.id,  requestedPeriod , practitionerId:  values?.practitioner, description: values?.title })
                .then((res) => {
                    success("Appointment Rescheduled");
                    if(keepOldSlot){
                        updateScheduleSlot({
                            slotId : data?.id,
                            start: data?.start,
                            end: data?.end,
                            status: "busy-reserved",
                            serviceCategory: data?.serviceCategory
                        })
                        .catch(()=>{})
                    }
                    handleShow();

                })
                .catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
                .finally(() => setBtnLoading(false));
            })
            
            .catch(() => {})
        },
    });
    
    useEffect(() => {
        if (modalShow?.id) {
            formik.setFieldValue("title",  modalShow.description);
            formik.setFieldValue("patient", modalShow.participant?.filter((data) => data?.actor?.type === "Patient")?.[0]?.actor?.display) ;
            formik.setFieldValue("description", modalShow.comment || "");
            formik.setFieldValue("returnTelephoneNumber", modalShow?.intakeQuestions?.callerNumber || "");
            formik.setFieldValue("returnTelephoneNumberExt", modalShow?.intakeQuestions?.callerNumberExt || "");
            formik.setFieldValue("practitioner", modalShow?.practitionerId || "");
            formik.setFieldValue("preferredChannel", modalShow?.intakeQuestions?.preferredChannel || "");
            
        }
    }, [modalShow])

    useEffect(() => {
        if (modalShow?.practitionerId) {
            getlistPractitioners({ orgId: modalShow?.orgId, scopeOfPractice: modalShow?.state })
                .then((res) => {
                    const practitioners = res.result.map(practitioner => ({
                        value: practitioner.id,
                        name: practitioner.name[0].text.charAt(0).toUpperCase() + practitioner.name[0].text.slice(1).toLowerCase()
                    })); 
                    setpractitionersList(practitioners);  
                })
                .catch((res) => {
                    failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                });
        }
    }, [modalShow])
   
    
    useEffect(() => {
        let startDate = (formik?.values?.date?.length > 0) ? moment(formik?.values?.date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")
        // if (formik?.values?.date) {
        checkSchedule({ actorId: formik?.values?.practitioner ? formik.values.practitioner:  user["custom:unique_id"], date: startDate, orgId: null }) 
            .then((res) => { 
                if(!res?.data) {
                   failed(res?.message);
                   setServiceCategory([]);
                   setCategoriesByDay({});
                   return;
                }
                
                let categories = [];
                if (!Array.isArray(res.data)) res.data = [res.data];
                    
                res.data.forEach(schedule => {
                let tempByDay = {};
                schedule.serviceCategory.forEach(category => {
                    let key = category?.date?.split('T')?.[0];
                    if(moment(key).isSameOrAfter(moment(startDate)) && 
                        !categories.some(item => item.value === category.id)) {
                        categories.push({...category, value: category?.id});                                
                    }
                    if(moment(key).isSameOrAfter(moment(startDate))) {
                        if(tempByDay[key]) {
                            if(!tempByDay[key].some(id => id === category.id )) 
                                tempByDay[key].push(category.id)
                            }
                        else tempByDay[key] = [category.id];   
                    }
                })
                setCategoriesByDay(Object.assign(categoriesByDay, tempByDay))
            })
            let reservedSlots ={}
            let newData = [];
            res.data.forEach(schedule => 
                getScheduleSlots(schedule.id, "busy-reserved")
                .then((resp) => {
                    resp.data.filter((slot)=> moment(slot?.start).isSameOrAfter(moment(startDate)))
                    ?.map((slot) => {
                        let serviceCategoryId = slot?.serviceCategory?.[0]?.id

                        if (reservedSlots.hasOwnProperty(serviceCategoryId)) {
                            reservedSlots[serviceCategoryId].push({
                                "start": slot?.start,
                                "end": slot?.end,
                                "id": slot?.id,
                                "serviceCategory": slot.serviceCategory,
                                "status": "busy-reserved"
                            });
                        }    
                        else {
                            reservedSlots[serviceCategoryId] = [{
                                "start": slot?.start,
                                "end": slot?.end,
                                "id": slot?.id,
                                "serviceCategory": slot.serviceCategory,
                                "status": "busy-reserved"
                            }];
                        }
                    })

                    Object.keys(reservedSlots).forEach(key => {
                        if(!newData.some(item => item.value === `${reservedSlots[key][0]?.serviceCategory?.[0]?.id}-busyReserved`))
                            newData.push({
                                name: `Reserved for ${reservedSlots[key]?.[0]?.serviceCategory[0]?.name}`,
                                value: `${reservedSlots[key][0]?.serviceCategory?.[0]?.id}-busyReserved`,
                                status: "busy-reserved"
                            });
                                
                        });
                        setReservedCategories(reservedSlots);
                        setServiceCategory([...categories, ...newData])                            
                    })
                    .catch((res) => {
                        failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                        setServiceCategory([]);
                        setCategoriesByDay({});
                    })
                );
            })
            .catch((res) => {
                failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                setServiceCategory([]);
                setCategoriesByDay({});
            });
    }, [modalShow?.id, formik?.values?.practitioner])


    const loadSlotData = (selectedDate, selectedType) => {
        setSlotsLoading(true);
        let date = (selectedDate) ? selectedDate : formik?.values?.date;
        let type = (selectedType) ? selectedType : formik?.values?.serviceCategory;
        date = moment(date).utc().format("YYYY-MM-DDTHH:mm:ss") + "Z"
        if(type?.endsWith("busyReserved")){
            let currentTime = moment(date);
            let slots = reservedCategories[type.split("-")[0]];
            slots = slots.filter(slot => moment(slot.start).isAfter(currentTime) && moment(slot.start).isSame(currentTime, "day"))
            slots = sortedSlots(slots);
            if (slots.length < 1) {
                setSlotUnavailable(true)
            }
            setAvailableSlots(slots)
            setSlotsLoading(false)
        } else if(type) {
            /** retrieve all slots here */
            let params = { actorId: formik?.values?.practitioner, date, serviceCategoryId: type, orgId: userOrgId }
            getSlots(params)
                .then((res) => {
                    setAvailableSlots(sortedSlots(res?.data?.availableSlots))
                    if (res?.data?.availableSlots < 1) {
                    setSlotUnavailable(true)
                }
                }).catch((res) => {
                    failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                    setAvailableSlots([])
                }).finally(() => { setSlotsLoading(false) })
        }
        else setSlotsLoading(false);  
    } 

    const handleDateSelection = (val) => {
        const isValidDate = moment(val, 'MMM-DD-YYYY').isValid()
        setAvailableSlots([])
        if(isValidDate){
            formik.setFieldValue("date", val.format("YYYY-MM-DD"));
            // load slots here
            loadSlotData(val.format("YYYY-MM-DD"))
        }else{
            formik.setFieldError('date', 'Invalid date format')
        }       
    }    

    const sortedSlots = (data) => {
        return data.sort((a, b) => {
          let atime = (moment(a.start).isValid()) ? `${a.start}`:`${moment(formik.values.date)?.format("YYYY-MM-DD")}T${a?.start}:00Z`;
          let btime = (moment(b.start).isValid()) ? `${b.start}`:`${moment(formik.values.date)?.format("YYYY-MM-DD")}T${b?.start}:00Z`;
          let aHr = moment(atime).hour();
          let bHr = moment(btime).hour();
          let aMin = moment(atime).minute();
          let bMin = moment(btime).minute();
          return aHr < bHr || (aHr == bHr && aMin < bMin) ? -1 : 1;
        });
    };

    const findUpcoming = (event) => {
        setSlotUnavailable(false);        
        let targetVal = event?.target?.value;
        formik.setFieldValue("serviceCategory", targetVal);
        setAvailableSlots([])
        formik.setFieldValue("date", "");
    }

    const highLightDates = () => {
        setAvailableDays([]);
        let today = moment();
        let toHighlight = [];
        if(formik?.values?.serviceCategory.endsWith("busyReserved")) {
            let category = formik.values.serviceCategory.split("-")[0];
            let slots = reservedCategories[category].filter(slot => moment(slot.start).isSameOrAfter(today));
            slots.forEach(slot => {
                let there = toHighlight.find( item => moment(item).isSame(moment(slot.start),'d'))
                if(!there) 
                    toHighlight.push(slot.start.split("T")[0]);
            })
        }
        else {
            for(const date in categoriesByDay) {
                if(moment(date).isSameOrAfter(today,'day')) {
                    if(categoriesByDay[date].some(item => item === formik.values.serviceCategory))
                        toHighlight.push(date);
                }
            }
        }
        setAvailableDays(toHighlight);
    }

    const renderDay = (props) => {
        const { selectedDate, day, dayComponent, ...other} = props;
        const today = moment();
        const isAvailable = availableDays.includes(day.format("YYYY-MM-DD"));
        const isSelected = day.isSame(selectedDate, "day");
        const isToday = day.isSame(today, "day");
        const isPast = day.isBefore(today, "day")

        return (
            <PickersDay {...dayComponent} 
                disabled={isPast || !isAvailable}
                style={isAvailable ? {background: "#accfeb"}: {background: "white" }} />
        );
    }

    const handlePractitionerChange = (event) => {   
        formik.setFieldValue("practitioner", event?.target?.value );
        formik.setFieldValue("serviceCategory", ""); 
        setAvailableSlots([])
    } 
    return (
        <Modal
            backdropClassName
            backdrop={'static'}
            size="xl" show={modalShow} onHide={handleShow}
            aria-labelledby="contained-modal-title-vcenter"
            centered className="custom-dialog">
            <Modal.Header closeButton className="border-0">
                <Modal.Title id="contained-modal-title-vcenter">
                    Rescheduling Appointment
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="common-form border-fields" onSubmit={formik.handleSubmit}>
                    
                    <>
                        <Row>
                            <Col>
                                <TextInput keyField={"title"} label={"Reason for visit"} formik={formik} placeholder={"Reason for visit"} />
                            </Col>
                            <Col>
                                <TextInput disabled = {true} keyField={"patient"} label={"Patient Name"} formik={formik} placeholder={"Patient Name"} style={{textTransform: "capitalize"}}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <SelectFiled keyField={"practitioner"} label={"Select Practitioner"} formik={formik} options={practitionersList} onChange={handlePractitionerChange} />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col>
                                <SelectFiled keyField={"serviceCategory"} label={"Appointment Type"} formik={formik} options={serviceCategory} disabled={!formik.values.practitioner} onChange={findUpcoming} />
                            </Col>
                            <Col>
                                <DateSelectorWithoutFormik disabled={!formik?.values?.serviceCategory} formik={formik} keyField={'date'} label="Date" value={moment(formik?.values?.date)} handleChange={(value) => { handleDateSelection(value)}} minDate={moment()} defaultValue={moment()} onMonthChange={highLightDates} onOpen={highLightDates} renderDay={(day, selectedDate, dayComponent) => renderDay({day, selectedDate, dayComponent})} />
                            </Col>
                        </Row>
                        {availableSlots?.length > 0 ?
                                <>
                                    <Row>
                                        <Col>Select Slot</Col>
                                    </Row>
                                    <Row style={{/*  justifyContent: "center", */ rowGap: "0.5rem", marginBottom: "1.5rem", marginTop: "0.5rem" }}>
                                        {availableSlots?.map((slot, index) => {
                                        let localTime = moment(slot?.start).format("HH:mm");
                                        
                                        return ( <Col style={{ background: selectedSlot === slot?.start ? "#6c757d38" : "#ffff", width: "14.666667%" }} sm={2} className="slotStyle" key={index} onClick={() => { formik?.setFieldValue("availableSlot", slot); setSelectedSlot(slot?.start) }}>{localTime}</Col>)
                                        })}
                                    </Row>
                                </>
                                : null}
                        {slotsLoading ?
                            <>
                                <Row>
                                    <Col>Select Slot</Col>
                                </Row>
                                <Skeleton height={50} />
                            </>
                            : null}
                        <Row>
                            {/* <Col>
                                <TextArea keyField={"description"} label={"Description"} formik={formik} placeholder={"Description"} />
                            </Col> */}
                            {modalShow?.intakeQuestions?.preferredChannel && (
                              <Col>
                                <Form.Check.Label style={{ textTransform: "capitalize", fontSize: "14px" }} >{"preferred channel ?"}</Form.Check.Label> <span className="requiredField">&#42;</span><br/>
                                {["phone", "video/chat"].map((val) => (
                                    <Form.Check style={{ textTransform: "capitalize", fontSize: "14px", marginRight: "65px"}} inline checked={formik.values.preferredChannel === val} type={"radio"} label={val} id={val} name="preferred channel" onChange={e => formik.setFieldValue("preferredChannel", e.target.id)} />
                                ))}
                            </Col>  
                            )}
                            
                            {modalShow?.intakeQuestions?.callerNumber && (
                              <Col>
                                <Row>
                                    <Col>
                                        <TextInput keyField={"returnTelephoneNumber"} type="phone" label={"Return Telephone Number"} formik={formik} disabled={true} placeholder={"Return Telephone Number"} required={true} />
                                    </Col>
                                    <Col>
                                        <TextInput keyField={"returnTelephoneNumberExt"} label={"Ext."} formik={formik} disabled={true} placeholder={"Ext."} required={false} />
                                    </Col>
                                </Row>
                            </Col>  
                            )}
                        </Row>
                    </>
                    
                    <div className="btn-wrap">
                        <Button
                            onClick={() => {
                                handleShow();
                            }}
                            variant="secondary"
                            title="Cancel"
                        >
                            Cancel
                        </Button>
                        <Button type="submit" isLoading={btnLoading}>
                            Done
                        </Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default ReschduleAppointment;
