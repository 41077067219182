import { Skeleton , Tooltip} from "@mui/material";
import React from "react";

const SelectFiled = ({ label, options = [], selectDisabledValue = false, keyField, formik, selectLabel = "Select", required = true, hideRequired = false, optional = true, tooltip = false, tooltipText = "", readOnly = false, isLoading = false, isError = false, ...props }) => {
 return (
  <div className="field-wrap">
   {label ? (
    <label htmlFor={keyField} style={{whiteSpace: "nowrap"}} className={readOnly ? "readonly" : ""}>
     {label}
     {hideRequired ? null : required ? <span style={{ color: "red" }}> *</span> : optional ? " (Optional)" : ""}
    </label>
   ) : null}
   {isLoading ? (
    <Skeleton height={40} animation="wave" />
   ) : (
    <Tooltip title = {tooltipText}><select id={keyField} className={readOnly ? "readonly" : ""} style={{ pointerEvents: readOnly ? "none" : "all", cursor: readOnly ? "auto" : "pointer" }} {...formik?.getFieldProps(keyField)} {...props}>
      <option value=""  disabled={selectDisabledValue ? false : true}>
        {options.length ? selectLabel : "No options"}
      </option>
      {options.map(({ value, name, disabled = false }, index) => (
        <option value={value} key={index} disabled={disabled}>
          {name}
        </option>
      ))}
    </select>
    </Tooltip>
   )}
   <div>{isError ? <div className="error-text">{isError}</div> : formik?.touched[keyField] && formik?.errors[keyField] ? <div className="error-text">{formik?.errors[keyField]}</div> : null}</div>
  </div>
 );
};

export default SelectFiled;
