import { useFormik } from "formik";
import React, { useEffect } from "react";
import * as Yup from "yup";
import { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import TextInput from "../../common/textfield/TextInput";
import TextArea from "../../common/textfield/TextArea";
import { usePatientsBySOPQuery } from "../../../hooks/ReactQueryHooks/usePatientsBySOPQuery";
import { useScheduleCategoryQuery } from "../../../hooks/ReactQueryHooks/useScheduleCategoryQuery";
import SelectFiled from "../../common/textfield/SelectFiled";
import { decryptData } from "../../EncryptDecrypt";
import { Button } from "../../common/Button";
import { CircularProgress, Skeleton } from "@mui/material";
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { DateSelectorWithoutFormik } from "../../common/textfield/DateSelector";
import moment from "moment";
import { bookAppointment, checkSchedule, getSlots, updateAppointmentRequest } from "../api/AppointmentRequests";
import {getScheduleSlots} from "../../api/Schedule";
import { failed, success } from "../../common/Toastify";
import "./style.css"
import { generatePass } from "./Constants";
import { LOCATIONS } from '../../common/location';
import { useSelector } from "react-redux";
import { getSignature } from "../../Zoom/functions";
import { Form } from "react-bootstrap";
import { getSingleUser } from "../../api/Individual";
import { useNavigate } from "react-router-dom";
import { editQuestionnaireResponse } from "../../api/Questionnaire";
import { buildSessionTopic } from "../../../utils/ZoomSessionSupport";

const BookAppointment = ({ modalShow, handleShow, type, formikInBoundCall, questionnaireResponseId, encounterIdFromModal}) => {
    const user = useSelector((state) => state?.auth?.user);
    const [patientsList, setPatientsList] = useState([]);
    const [btnLoading, setBtnLoading] = useState(false);
    const [slotsLoading, setSlotsLoading] = useState(false);
    const [selectedSlot, setSelectedSlot] = useState("");
    const [reservedCategories, setReservedCategories] = useState ({})
    const [serviceCategory, setServiceCategory] = useState([]);
    const [categoriesByDay, setCategoriesByDay] = useState({});
    const [availableSlots, setAvailableSlots] = useState([]);
    const [availableDays, setAvailableDays] = useState([]);
    const [practionerList, setPractionerList] = useState([]);
    const [practionerId, setPractionerId] = useState( modalShow?.id );
    const [slotUnavailable, setSlotUnavailable] = useState(false);
    const [scopeOfPractice, setScopeOfPractice] = useState(null);
    const userOrg = useSelector((state) => state?.auth?.user?.organizations)
    const userOrgId = userOrg?.[0]?.id;

    const navigate = useNavigate()
    const isTagPresent = (tagValue) => formikInBoundCall?.values?.questionResponse?.some(item => item.tag === tagValue);
    const taggedQuestions = formikInBoundCall?.values?.questionResponse?.filter(item => item?.tag && item.tag !== null);
    const getValueQuestionnair = (tagValue) => formikInBoundCall?.values?.questionResponse?.find(item => item.tag === tagValue)?.answer?.[0]?.answer
    const getValueQuestionnairDropDown = (tagValue) => formikInBoundCall?.values?.questionResponse.find((obj)=> obj?.tag === tagValue)?.answer?.find((val)=>val?.answer !== "")?.answer
    const getValueQuestionnairRadio = (tagValue) => formikInBoundCall?.values?.questionResponse.find((obj)=> obj?.tag === tagValue)?.answer?.find((val)=>val?.answer === true)?.option
    const getRadiOptions = (tagValue) => formikInBoundCall?.values?.questionResponse.find((obj)=> obj?.tag === tagValue)?.answer
    const getValueQuestionnairExt = (tagValue) => formikInBoundCall?.values?.questionResponse?.find(item => item.tag === tagValue)?.answer?.[0]?.answer?.split("ext ")[1]
    const onSuccess = (data) => {
        setPatientsList(data?.map((org) => (org = { value: `{"id": "${org?.id}", "location": "${org?.address[0]?.state}"}`, name: decryptData(org?.name?.[0]?.text?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')), id: org.id })));
    };

    useScheduleCategoryQuery({
        onSuccess: (data) => setServiceCategory(data.map((item) => ({
            name: `${item?.name} (${item?.timeSlot?.value} min)`,
            value: item.id,
            id: item.id,
            timeSlot: item.timeSlot,
        })))
    })

    const { isLoading, } = usePatientsBySOPQuery({ onSuccess, practitioner_id: modalShow?.id, org_id: modalShow?.managingOrganization?.[0]?.reference?.split('/')?.[1] })
    const formik = useFormik({
        initialValues: {
            title: "",
            patient: "",
            date: "",
            serviceCategory: "",
            availableSlot: "",
            preferredChannel: "",
            returnTelephoneNumber: "",
            returnTelephoneNumberExt: "",
            practitionerPreference: modalShow?.id ? "select practitioner" : "",
            practitioner: modalShow?.id ? modalShow?.id : ""
        },
        validationSchema: Yup.object({
            title: Yup.string().required("Required Reason for visit field."),
            patient: Yup.string().required("Required Patient field."),
            date: Yup.string().required("Required Date field."),
            serviceCategory: Yup.string().required("Required Appointment type field."),
            availableSlot: Yup.object().required("Required slots field."),
            preferredChannel: Yup.string().required("Required Preferred channel field."),
            returnTelephoneNumber: Yup.string().matches(/^(?:\+?1[-. ]?)?\(?[0-9]\d{2}\)?[-. ]?\d{3}[-. ]?\d{4}$/, "Enter Valid Return Telephone Number.").required("Required Return Telephone Number field."),
            returnTelephoneNumberExt: Yup.string().matches(/^\d{0,4}$/, 'Must be 4 digit or less.'),
            practitionerPreference: type === "patient" ? Yup.string().required("Required Practitioner Preference field.") : null,
            practitioner:type === "patient" ?  Yup.mixed().when(["practitionerPreference"], {
                is: (practitionerPreference) => practitionerPreference !== "no preference",
                then: Yup.string().required("Required Practitioner field."),
                otherwise: Yup.mixed().notRequired(),
            }) : null,
        }),
        onSubmit: (values) => {
            setBtnLoading(true);
            let slot = null;
            let utcStart = values?.availableSlot?.start;
            let utcEnd = values?.availableSlot?.end;
            let selectedServiceCategory = values?.serviceCategory;
            // if(selectedServiceCategory.endsWith("busyReserved")){
            //     selectedServiceCategory = selectedServiceCategory.split("-")?.[0];
            // }

            selectedServiceCategory = serviceCategory.filter((categoryData) => categoryData.value === selectedServiceCategory)?.[0]
            if(selectedServiceCategory?.value?.endsWith("busyReserved")) {
                selectedServiceCategory.id = selectedServiceCategory?.value?.split("-")[0];
                selectedServiceCategory.name = selectedServiceCategory.name.substring(12).trim();
            }

            let serviceCategoryPayload = [
                {
                  coding: [
                    {
                      system: "http://florizel.com/AppointmentServiceCategory",
                      code: selectedServiceCategory?.id,
                      display: selectedServiceCategory?.display ? selectedServiceCategory?.display : selectedServiceCategory?.name
                    }
                  ],
                  text: `Appointment for ${selectedServiceCategory?.display ? selectedServiceCategory?.display : selectedServiceCategory?.name}`
                }
            ]
            
            if(values?.availableSlot?.status === "busy-reserved"){
                utcStart= values?.availableSlot?.start
                utcEnd = values?.availableSlot?.end
                slot = values?.availableSlot;
            }
            else if(formik?.values?.practitionerPreference === "no preference") {
                values.practitioner = values?.availableSlot?.actorId;
                values.scheduleId = values?.availableSlot?.scheduleId;
            }
            else values.scheduleId = values?.availableSlot?.scheduleId;
            
            const requestedPeriod = [{start: utcStart, end: utcEnd}]   
            let topic = buildSessionTopic(user?.name[0]?.text);
            
            let password= generatePass();   
            getSignature({ topic: topic.trim(), role: 0, password: password })
            .then(async (res) => {
                const sessionToken = res?.data?.sessionToken;
                let intakeQuestions = {}
                let patientLocation;
                    if(type === "patient"){
                        for (let element of taggedQuestions) {
                            if (element.tag == 'TELEPHONE') { 
                                intakeQuestions[element.text] =  element.answer[0].answer; 
                                intakeQuestions.callerNumber = values?.returnTelephoneNumber 
                                if (values?.returnTelephoneNumberExt) {
                                    intakeQuestions.callerNumberExt = values?.returnTelephoneNumberExt 
                                }
                            }
                            else if(element.tag == 'LOCATION') {
                                const selected = element.answer.find((val) => val.answer.length > 0);
                                intakeQuestions[element.text] = LOCATIONS[selected.answer];  
                                patientLocation = LOCATIONS[selected.answer]; 
                            }
                            else if(element.tag == "CALLER") { 
                                intakeQuestions[element.text] = (element?.answer?.[0]?.answer || "") +  ' ' +  (element?.answer?.[1]?.answer || "");
                                intakeQuestions.callerName = getValueQuestionnair("CALLER");
                            }
                            else if(element.tag == "SELFCALLING") {
                                if(getValueQuestionnairRadio("SELFCALLING") === "Yes"){ 
                                    intakeQuestions[element.text] = "Self"
                                    intakeQuestions.callerRelationship = "Self"
                                }  
                                else if(isTagPresent("RELATIONSHIP")) {
                                    intakeQuestions[element.text] = getValueQuestionnairDropDown("RELATIONSHIP");
                                    intakeQuestions.callerRelationship = getValueQuestionnairDropDown("RELATIONSHIP");
                                }
                            }
                            else if(element.tag && element.tag !== "RELATIONSHIP") {
                                let value = null
                                if(element.answerType == "dropDown") {
                                    value = element.answer.find((val) => val.answer.length > 0);
                                    intakeQuestions[element.text] = value.answer;
                                }
                                else if(element.answerType == "radio") {
                                    value = element.answer.filter((val) => val.answer);
                                    intakeQuestions[element.text]= value.length > 0 ? value[0].option : "" 
                                }
                                else {
                                    value = element.answer[0].answer;
                                    intakeQuestions[element.text]= value;
                                }
                            }
                        }
                        intakeQuestions["questionnaireResponseId"] = {reference: `QuestionnaireResponse/${questionnaireResponseId}`}
                        intakeQuestions["preferredChannel"] = values?.preferredChannel
                    }else{
                        intakeQuestions["callerNumber"] = values?.returnTelephoneNumber
                        intakeQuestions["callerNumberExt"] = values?.returnTelephoneNumberExt
                        intakeQuestions["preferredChannel"] = values?.preferredChannel
                    }

                bookAppointment({serviceCategory: serviceCategoryPayload, slot, sessionToken, practitionerId: type === "patient" ? values?.practitioner  : modalShow?.id, patientId: type === "patient" ? formikInBoundCall?.values?.patientId : JSON.parse(values?.patient)?.id, requestedPeriod, description: values?.title, scheduleId: values?.scheduleId, intakeQuestions, patientLocation:  patientLocation, newEncounterIdFromModal: encounterIdFromModal})
                  .then((res) => {
                      if (type === "patient") {
                        localStorage.removeItem("firstName")
                        localStorage.removeItem("lastName")
                    //   let updatePayload = {isHostJoined: true, sessionToken: sessionToken, id: res?.result?.id, status: "booked", patientId: res?.result?.patientId, practitionerId: res?.result?.practitionerId, proceedWith: formikInBoundCall?.values.howToProcced, questionnaireResponse}
                    let updatePayload = {isHostJoined: false, sessionToken: sessionToken, id: res?.result?.id, status: "booked", patientId: res?.result?.patientId, practitionerId: res?.result?.practitionerId, proceedWith: formikInBoundCall?.values.howToProcced, intakeQuestions, patientLocation: patientLocation}
                      updateAppointmentRequest(updatePayload)
                      .then((res) => {
                        if(res.status === true){
                            editQuestionnaireResponse(questionnaireResponseId, {encounterId: res?.result?.encounterId}).then((res)=>{
                                handleShow();
                                navigate("/app/appointment", { state:{ proceedWith: formikInBoundCall?.values.howToProcced, requestedPeriod: requestedPeriod }});
                              }).catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
                              .finally(() => setBtnLoading(false));
                        }
                      })
                      .catch((err) => failed(err?.response?.data?.message || err?.response?.data?.error ||  err?.message)
                      ).finally(() => setBtnLoading(false));
                    }
                    success("Appointment Scheduled");
                    handleShow();
                    navigate("/app/appointment")
                  })
                  .catch((res) => failed( res?.response?.data?.message || res?.response?.data?.error || res.message))
                  .finally(() => setBtnLoading(false));
              })
              .catch((error) => console.log("getsignature error", { error }));
        },
    });
    useEffect(() => {
        let startDate = (formik?.values?.date?.length > 0) ? moment(formik?.values?.date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")
        // if (formik?.values?.date) {
        if(formik?.values?.practitionerPreference &&
             ((formik?.values?.practitionerPreference === "select practitioner" && practionerId)
                || formik?.values?.practitionerPreference === "no preference" )) {
            checkSchedule({ actorId: modalShow?.id ? modalShow?.id : formik?.values?.practitionerPreference === "no preference" ? user["custom:unique_id"] :  practionerId, date: startDate, orgId: formik?.values?.practitionerPreference === "no preference" ? user?.organizations[0]?.id : null }) 
            .then((res) => { 
                    if(!res?.data) {
                        failed(res?.message);
                        setServiceCategory([]);
                        setCategoriesByDay({});
                        return;
                    }
                    
                    let categories = [];
                    if (!Array.isArray(res.data)) res.data = [res.data];
                    
                    res.data.forEach(schedule => {
                        let tempByDay = {};
                        schedule.serviceCategory.forEach(category => {
                            let key = category?.date?.split('T')?.[0];
                            if(moment(key).isSameOrAfter(moment(startDate)) && 
                                !categories.some(item => item.value === category.id)) {
                                categories.push({...category, value: category?.id});                                
                            }
                            if(moment(key).isSameOrAfter(moment(startDate))) {
                                if(tempByDay[key]) {
                                    if(!tempByDay[key].some(id => id === category.id )) 
                                        tempByDay[key].push(category.id)
                                }
                                else tempByDay[key] = [category.id];   
                            }
                        })
                        setCategoriesByDay(Object.assign(categoriesByDay, tempByDay))                       
                    })
                    let reservedSlots ={}
                    let newData = [];
                    res.data.forEach(schedule => 
                        getScheduleSlots(schedule.id, "busy-reserved")
                        .then((resp) => {
                            resp.data.filter((slot)=> moment(slot?.start).isSameOrAfter(moment(startDate)))
                            ?.map((slot) => {
                                let serviceCategoryId = slot?.serviceCategory?.[0]?.id
                                
                                if (reservedSlots.hasOwnProperty(serviceCategoryId)) {
                                    reservedSlots[serviceCategoryId].push({
                                        "start": slot?.start,
                                        "end": slot?.end,
                                        "id": slot?.id,
                                        "serviceCategory": slot.serviceCategory,
                                        "status": "busy-reserved"
                                    });
                                }    
                                else {
                                    reservedSlots[serviceCategoryId] = [{
                                        "start": slot?.start,
                                        "end": slot?.end,
                                        "id": slot?.id,
                                        "serviceCategory": slot.serviceCategory,
                                        "status": "busy-reserved"
                                }];
                                }
                            })

                            Object.keys(reservedSlots).forEach(key => {
                                if(!newData.some(item => item.value === `${reservedSlots[key][0]?.serviceCategory?.[0]?.id}-busyReserved`))
                                newData.push({
                                    name: `Reserved for ${reservedSlots[key]?.[0]?.serviceCategory[0]?.name}`,
                                    value: `${reservedSlots[key][0]?.serviceCategory?.[0]?.id}-busyReserved`,
                                    status: "busy-reserved"
                                });
                                
                            });
                            setReservedCategories(reservedSlots);
                            setServiceCategory([...categories, ...newData])                            
                        })
                        .catch((res) => {
                            failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                            setServiceCategory([]);
                            setCategoriesByDay({});
                        })
                    );
                })
                .catch((res) => {
                    failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                    setServiceCategory([]);
                    setCategoriesByDay({});
                });
            }
    }, [modalShow?.id, practionerId])


    const handlePatientChange = (event) => {
        formik.setFieldValue("patient", event?.target?.value);
        setScopeOfPractice(JSON.parse(event?.target?.value)?.location); 
    };

    const sortedSlots = (data) => {      
        return data.sort((a,b) => {
            let atime = (moment(a.start).isValid()) ? `${a.start}`:`${moment(formik.values.date)?.format("YYYY-MM-DD")}T${a?.start}:00Z`;
            let btime = (moment(b.start).isValid()) ? `${b.start}`:`${moment(formik.values.date)?.format("YYYY-MM-DD")}T${b?.start}:00Z`;
            let aHr = moment(atime).hour();
            let bHr = moment(btime).hour();
            let aMin = moment(atime).minute();
            let bMin = moment(btime).minute();
            return (aHr < bHr || (aHr == bHr && aMin < bMin)) ? -1 : 1
        })
    }

    const findUpcoming = (event) => {
        setSlotUnavailable(false);        
        let targetVal = event?.target?.value;
        formik.setFieldValue("serviceCategory", targetVal);
        setAvailableSlots([])
        formik.setFieldValue("date", "");
    }

    const highLightDates = () => {
        setAvailableDays([]);
        let today = moment();
        let toHighlight = [];
        const isBusyReserved = formik.values.serviceCategory.endsWith('busyReserved');
        if (!isBusyReserved) {
            for (const date in categoriesByDay) {
                if (moment(date).isSameOrAfter(today, 'day')) {
                    if (categoriesByDay[date].some(item => item === formik.values.serviceCategory)) {
                        toHighlight.push(date);
                    }
                }
            }
        } else {
            for (const reservedDate in reservedCategories) {
                const reservations = reservedCategories[reservedDate];
                reservations.forEach(reservation => {
                    if (reservation.serviceCategory.some(category => formik.values.serviceCategory.includes(category.id))) {
                        const reservationStart = moment(reservation.start);
                        if (reservationStart.isSameOrAfter(today, 'day')) {
                            toHighlight.push(reservationStart.format('YYYY-MM-DD'));
                        }
                    }
                });
            }
        }
        setAvailableDays(toHighlight);
    }

    const renderDay = (props) => {
        const { selectedDate, day, dayComponent, ...other} = props;
        const today = moment();
        const isAvailable = availableDays.includes(day.format("YYYY-MM-DD"));
        const isSelected = day.isSame(selectedDate, "day");
        const isToday = day.isSame(today, "day");
        const isPast = day.isBefore(today, "day")

        return (
            <PickersDay {...dayComponent} 
                disabled={isPast || !isAvailable}
                style={isAvailable ? {background: "#accfeb"}: {background: "white" }} />
        );
    }

    // const handleServiceCategory = (event) => {
    //     setSlotUnavailable(false)
    //     let targetVal = event?.target?.value;
    //     formik.setFieldValue("serviceCategory", targetVal);
    //     setSlotsLoading(true)
    //     if(targetVal?.endsWith("busyReserved")){
    //         let currentTime = moment();
    //         let slots = reservedCategories[targetVal.split("-")[0]];
    //         slots = slots
    //                 .filter(slot => moment(slot.start).isAfter(currentTime))
    //         slots = sortedSlots(slots);
    //         if (slots.length < 1) {
    //             setSlotUnavailable(true)
    //         }
    //         setAvailableSlots(slots)
    //         setSlotsLoading(false)
    //     }else if(targetVal) {
    //         /** retrieve all slots here */
    //         let params = { actorId: modalShow?.id || practionerId || user["custom:unique_id"], date: moment(formik?.values?.date).format("YYYY-MM-DD"), serviceCategoryId: targetVal, orgId: formik?.values?.practitionerPreference === "no preference" ? user?.organizations[0]?.id : userOrgId }
    //         if(formik?.values?.practitionerPreference === "no preference") {
    //             if(scopeOfPractice)
    //                 params.scopeOfPractice = scopeOfPractice
    //             else if(taggedQuestions) {
    //                 let temp = LOCATIONS[taggedQuestions.filter(el => el.tag === "LOCATION")[0].answer.find((val) => val.answer.length > 0).answer];
    //                 setScopeOfPractice(temp);
    //                 params.scopeOfPractice = temp;
    //             }
    //         }
    //         getSlots(params)
    //         .then((res) => {
    //             setAvailableSlots(sortedSlots(res?.data?.availableSlots))
    //             if (res?.data?.availableSlots < 1) {
    //                 setSlotUnavailable(true)
    //             }
    //         }).catch((res) => {
    //             failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
    //             setAvailableSlots([])
    //         }).finally(() => { setSlotsLoading(false) })
    //     }
    //     else setSlotsLoading(false);        
    // };

    // const categoryNotAvailable = (category) => {

    // }

    const loadSlotData = (selectedDate, selectedType) => {
        setSlotsLoading(true);
        let date = (selectedDate) ? selectedDate : formik?.values?.date;
        let type = (selectedType) ? selectedType : formik?.values?.serviceCategory;
        date = moment(date).utc().format("YYYY-MM-DDTHH:mm:ss") + "Z"
        if(type?.endsWith("busyReserved")){
            let currentTime = moment(date);
            let slots = reservedCategories[type.split("-")[0]];
            slots = slots.filter(slot => moment(slot.start).isAfter(currentTime) && moment(slot.start).isSame(currentTime, "day"))
            slots = sortedSlots(slots);
            if (slots.length < 1) {
                setSlotUnavailable(true)
            }
            setAvailableSlots(slots)
            setSlotsLoading(false)
        } else if(type) {
            /** retrieve all slots here */
            let params = { actorId: modalShow?.id || practionerId || user["custom:unique_id"], date, serviceCategoryId: type, orgId: formik?.values?.practitionerPreference === "no preference" ? user?.organizations[0]?.id : userOrgId }
            if(formik?.values?.practitionerPreference === "no preference") {
                if(scopeOfPractice)
                    params.scopeOfPractice = scopeOfPractice
                else if(taggedQuestions) {
                    let temp = LOCATIONS[taggedQuestions.filter(el => el.tag === "LOCATION")[0].answer.find((val) => val.answer.length > 0).answer];
                    setScopeOfPractice(temp);
                    params.scopeOfPractice = temp;
                }
            }
            getSlots(params)
                .then((res) => {
                    setAvailableSlots(sortedSlots(res?.data?.availableSlots))
                    if (res?.data?.availableSlots < 1) {
                    setSlotUnavailable(true)
                }
                }).catch((res) => {
                    failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                    setAvailableSlots([])
                }).finally(() => { setSlotsLoading(false) })
        }
        else setSlotsLoading(false);  
    } 

    const handleDateSelection = (val) => {
        const isValidDate = moment(val, 'MMM-DD-YYYY').isValid()
        setAvailableSlots([])
        if(type === "patient"){
            if(formik.values.practitionerPreference == ''){
                failed('Please select practitioner preference')
            }else{
                if(isValidDate){
                    formik.setFieldValue("date", val.format("YYYY-MM-DD"));
                    // load slots here
                    loadSlotData(val.format("YYYY-MM-DD"))
                }else{
                    formik.setFieldError('date', 'Invalid date format')
                }
            }
        }else{
            if(isValidDate){
                formik.setFieldValue("date", val.format("YYYY-MM-DD"));
                loadSlotData(val.format("YYYY-MM-DD"))
            }else{
                formik.setFieldError('date', 'Invalid date format')
            }
        }       
    }

    // const handleDateChange = (val) => {
    //     const isValidDate = moment(val, 'MMM-DD-YYYY').isValid()
    //     if(type === "patient"){
    //         if(formik.values.practitionerPreference == ''){
    //             failed('Please select practitioner preference')
    //         }else{
    //             if(isValidDate){
    //                 formik.setFieldValue("date", val.format("YYYY-MM-DD"));
    //                 formik.setFieldValue("serviceCategory", "");
    //                 setAvailableSlots([])
    //             }else{
    //                 formik.setFieldError('date', 'Invalid date format')
    //             }
    //         }
    //     }else{
    //         if(isValidDate){
    //             formik.setFieldValue("date", val.format("YYYY-MM-DD"));
    //             formik.setFieldValue("serviceCategory", "");
    //             setAvailableSlots([])
    //         }else{
    //             formik.setFieldError('date', 'Invalid date format')
    //         }
    //     }
    // }

    useEffect(() => {
        getSingleUser({ orgId : user?.organizations[0]?.id , type : "Practitioner"})
          .then((res) => {
            setPractionerList(res?.data?.map((ele) => (ele = { value: ele?.id, name: decryptData(ele?.name?.[0]?.text?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') ), id: ele?.id })))
          })
          .catch((res) => {
            console.log("error fetching practioner list",res)
          })
      }, []);

    const handlePractitionerChange = (event) => {
        setPractionerId(event?.target?.value)
        formik.setFieldValue("practitioner", formik?.values?.practitionerPreference === "no preference" ? "" : event?.target?.value);
        setAvailableSlots([]);
    };

    let isReadOnly = true

    useEffect(() => {
        if(type === "patient"){
            formik.setFieldValue("title", isTagPresent("REASON") ?  getValueQuestionnair("REASON") : "");
            formik.setFieldValue("patient", decryptData(formikInBoundCall?.values?.patientName));
            formik.setFieldValue("returnTelephoneNumber", isTagPresent("TELEPHONE") ?  formikInBoundCall?.values?.questionResponse?.find(item => item.tag === "TELEPHONE")?.answer?.[0]?.answer.split(" ext")[0] : "");
            formik.setFieldValue("returnTelephoneNumberExt" , isTagPresent("TELEPHONE")? getValueQuestionnairExt("TELEPHONE") : "");
        }

        return ()=> {
            let nameAnswer;
            const nameQuestion = formikInBoundCall?.values?.questionResponse.find(question => question.tag === "CALLER");
            if (nameQuestion) {
                 nameAnswer = nameQuestion?.answer;
                  nameAnswer[0].answer = localStorage.getItem("firstName")
                  if(nameQuestion?.answer?.[1] === undefined) nameAnswer.push({answer: localStorage.getItem("lastName")}) 
                  nameAnswer[0].required = true;
                  nameAnswer[0].option = null;
                  nameAnswer[0].value = null;
                  nameAnswer[0].name = null;
            }
            formikInBoundCall?.setFieldValue("questionResponse", formikInBoundCall?.values?.questionResponse)
        }
      }, []);

    return (
        <Modal
            backdropClassName
            backdrop={'static'}
            size="xl" show={modalShow} onHide={handleShow}
            aria-labelledby="contained-modal-title-vcenter"
            centered className="custom-dialog">
            <Modal.Header closeButton className="border-0">
                <Modal.Title id="contained-modal-title-vcenter">
                    Create Appointment for <span style={{ textTransform: "capitalize" }}>{modalShow?.name?.[0]?.text||formikInBoundCall?.values?.patientName}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="common-form border-fields" onSubmit={formik.handleSubmit}>
                    {isLoading ? (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "50vh",
                            }}
                        >
                            <CircularProgress />
                        </div>
                    ) : (
                        <>
                            <Row>
                                <Col>
                                    <TextInput keyField={"title"} label={"Reason for visit"} formik={formik} placeholder={"Reason for visit"} />
                                </Col>
                                <Col>
                                    {!type ? (<SelectFiled keyField={"patient"} label={"Patient Name"} formik={formik} options={patientsList}  onChange={handlePatientChange} />)
                                    : (<TextInput keyField={"patient"} label={"Patient Name"} formik={formik} disabled={true} placeholder={"Patient Name"} style={{textTransform: "capitalize"}} />)}
                                </Col>
                            </Row>
                            {type === "patient" ? 
                            <Row>
                                <Col>
                                    <Form.Check.Label style={{ textTransform: "capitalize", fontSize: "14px" }} >{"practitioner preference ?"}</Form.Check.Label> <span className="requiredField">&#42;</span><br/>
                                    {["select practitioner", "no preference"].map((val) => (
                                        <Form.Check style={{ textTransform: "capitalize", fontSize: "14px", marginRight: "45px"}} inline checked={formik.values.practitionerPreference === val} type={"radio"} label={val} id={val} name="practitioner preference"  onChange={e => {setPractionerId(null); formik.setFieldValue("practitionerPreference", e.target.id)}} />
                                     ))}
                                     {formik?.touched["practitionerPreference"] && formik?.errors["practitionerPreference"] ? (<div className="error-text">{formik?.errors["practitionerPreference"]} </div>) : null}
                                </Col>
                                {formik?.values?.practitionerPreference === "select practitioner" ? (
                                    <Col>
                                        <SelectFiled keyField={"practitioner"} label={"Select Practitioner"} formik={formik} options={practionerList} isReadOnly={isReadOnly} onChange={handlePractitionerChange} />
                                    </Col>
                                ) : <Col></Col>}
                                
                            </Row> : null}
                            <Row className="mt-3">
                                <Col>
                                    <SelectFiled keyField={"serviceCategory"} label={"Appointment Type"} formik={formik} options={serviceCategory} disabled={!formik.values.practitionerPreference} onChange={findUpcoming} />
                                </Col>
                                <Col>
                                    <DateSelectorWithoutFormik disabled={!formik?.values?.serviceCategory} formik={formik} keyField={'date'} label="Date" value={moment(formik?.values?.date)} handleChange={(value) => { handleDateSelection(value)}} minDate={moment()} defaultValue={moment()} onMonthChange={highLightDates} onOpen={highLightDates} renderDay={(day, selectedDate, dayComponent) => renderDay({day, selectedDate, dayComponent})} />
                                </Col>
                            </Row>
                            {availableSlots?.length > 0 ?
                                <>
                                    <Row>
                                        <Col>Select Slot</Col>
                                    </Row>
                                    <Row style={{ rowGap: "0.5rem", marginBottom: "1.5rem", marginTop: "0.5rem" }}>
                                        {availableSlots?.map((slot, index) => {
                                        let localTime = moment(slot?.start).format("HH:mm");
                                        
                                        return ( <Col style={{ background: selectedSlot === slot?.start ? "#6c757d38" : "#ffff", width: "14.666667%" }} sm={2} className="slotStyle" key={index} onClick={() => { formik?.setFieldValue("availableSlot", slot); setSelectedSlot(slot?.start) }}>{localTime}</Col>)
                                        })}
                                    </Row>
                                </>
                                : <div className="error-text" style={{marginBottom: "1.5rem", display: slotUnavailable ?  "block" : "none" }}>
                                No Available Slots
                             </div>}
                                
                                {slotsLoading ?
                                <>
                                    <Row>
                                        <Col>Select Slot</Col>
                                    </Row>
                                    <Skeleton height={50} />
                                </>
                                : null}
                            <Row>
                                <Col>
                                    <Form.Check.Label style={{ textTransform: "capitalize", fontSize: "14px" }} >{"preferred channel ?"}</Form.Check.Label> <span className="requiredField">&#42;</span><br/>
                                    {["phone", "video/chat"].map((val) => (
                                        <Form.Check style={{ textTransform: "capitalize", fontSize: "14px", marginRight: "65px"}} inline checked={formik.values.preferredChannel === val} type={"radio"} label={val} id={val} name="preferred channel"  onChange={e => formik.setFieldValue("preferredChannel", e.target.id)} />
                                     ))}
                                     {formik?.touched["preferredChannel"] && formik?.errors["preferredChannel"] ? (<div className="error-text">{formik?.errors["preferredChannel"]} </div>) : null}
                                </Col>
                                {type === "patient" ? (isTagPresent("TELEPHONE")) ? (
                                <>
                                <Col>
                                    <Row>
                                        <Col>
                                            <TextInput keyField={"returnTelephoneNumber"} type="phone" label={"Return Telephone Number"} formik={formik} disabled={type === "patient" ? isTagPresent("TELEPHONE") ? true : false : false} placeholder={"Return Telephone Number"} required={true} />
                                        </Col>
                                        <Col>
                                            <TextInput keyField={"returnTelephoneNumberExt"} label={"Ext."} formik={formik} disabled={type === "patient" ? isTagPresent("TELEPHONE") ? true : false : false} placeholder={"Ext."} required={false} />
                                        </Col>
                                    </Row>
                                </Col>
                            </>) :  null :
                                <>
                                        <Col>
                                            <Row>
                                                <Col>
                                                    <TextInput keyField={"returnTelephoneNumber"} type="phone" label={"Return Telephone Number"} formik={formik} disabled={type === "patient" ? isTagPresent("TELEPHONE") ? true : false : false} placeholder={"Return Telephone Number"} required={true} />
                                                </Col>
                                                <Col>
                                                    <TextInput keyField={"returnTelephoneNumberExt"} label={"Ext."} formik={formik} disabled={type === "patient" ? isTagPresent("TELEPHONE") ? true : false : false} placeholder={"Ext."} required={false} />
                                                </Col>
                                            </Row>
                                        </Col>

                                    </>
                                }
                            </Row>
                            
                            <Row></Row>
                            <Row>
                                {/* <Col>
                                    <TextArea keyField={"description"} label={"Description"} formik={formik} placeholder={"Description"} />
                                </Col> */}
                            </Row>
                        </>
                    )}
                    <div className="btn-wrap">
                        <Button
                            onClick={() => {
                                handleShow();
                            }}
                            variant="secondary"
                            title="Cancel"
                        >
                            Cancel
                        </Button>
                        <Button type="submit" isLoading={btnLoading}>
                            Create
                        </Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default BookAppointment;
